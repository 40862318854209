<template>
  <BaseWindow
    @closeWindow="closeWindow"
    :show="true"
    :titleText="$t('general.case')"
  >
    <div class="case-container">
      <div class="case">
        <div class="case-profile">
          <Avatar
            class="player-window-avatar"
            :url="user.avatar"
            :crown="myCrownCondition(user.crown, user.vip.status)"
            size="200"
            :lazy="false"
          />
          <div class="profile-sprite profil-button">
            <div class="orange-content">
              <div class="profile-sprite dollar-icon"></div>
            </div>
            <span class="profil-button-chip">{{
              getChipFormat(parseInt(user.availableChip))
            }}</span>
          </div>
          <div
            v-if="!user.vip.status"
            class="case-button ui-button ui-button-orange"
            @click="showShopWindow('buy-vip')"
          >
            {{ $t("caseWindow.buyVip") }}
          </div>
        </div>
        <div class="case-content" v-if="user.vip.status">
          <p class="case-content-title">
            {{ $t("caseWindow.theretentation") }}
          </p>
          <div class="case-content-image">
            <div class="case-content-image-card">
              <div class="selector">
                <div class="sprite-profile-popup language-ok"></div>

                <div
                  class="source-selector"
                  @click="sourceOptions = !sourceOptions"
                >
                  {{
                    selectedSource === "game"
                      ? $t("caseWindow.fromGame")
                      : $t("caseWindow.fromCase")
                  }}
                </div>
                <div class="source-selector-options" v-if="sourceOptions">
                  <div
                    class="source-selector-options-game"
                    @click="selectOptions('game')"
                  >
                    {{ $t("caseWindow.fromGame") }}
                  </div>
                  <div
                    class="source-selector-options-game"
                    @click="selectOptions('case')"
                  >
                    {{ $t("caseWindow.fromCase") }}
                  </div>
                </div>
              </div>
              <span class="case-content-image-card-info">{{
                $t("caseWindow.chipInGame")
              }}</span>
              <div class="case-content-image-card-amount">
                {{ getChipFormat(parseInt(user.availableChip)) }}
              </div>
              <div class="sprite-profile-popup window3-image"></div>
            </div>
            <div class="case-content-image-case">
              <input
                type="number"
                :placeholder="$t('caseWindow.amount')"
                class="case-amount-input"
                v-model="selectedAmount"
              />
              <div v-show="selectedAmount > 0" class="formatted-amount">
                {{ getChipFormat(parseInt(selectedAmount)) }}
                <span class="format-small">
                  {{ getShortChipFormat(parseInt(selectedAmount)) }}
                </span>
              </div>
              <span class="case-content-image-case-info">{{
                $t("caseWindow.chipInCase")
              }}</span>
              <div class="case-content-image-case-amount">
                {{ getChipFormat(parseInt(getTotalCaseAmount)) }}
              </div>
              <div class="profile-sprite case-image"></div>
            </div>
          </div>
          <div
            class="case-button ui-button ui-button-small-orange"
            @click="caseAction"
          >
            <span class="case-button-text">{{
              $t("caseWindow.transfer")
            }}</span>
          </div>
        </div>
        <div class="case-content" v-else>
          <p class="desc">{{ $t("caseWindow.onlyVip") }}</p>
        </div>
      </div>
    </div>
  </BaseWindow>
</template>

<script>
import { mapState, mapGetters } from "vuex";

export default {
  name: "CaseWindow",
  components: {
    Avatar: () => import("@/components/Misc/Avatar"),
    BaseWindow: () => import("../BaseWindow"),
  },
  computed: {
    ...mapState(["user"]),
    ...mapGetters(["getChipFormat", "getShortChipFormat", "getRealPlatform"]),
    getTotalCaseAmount() {
      return this.user?.userCase?.total ?? 0;
    },
  },
  data() {
    return {
      selectedAmount: null,
      selectedSource: "game",
      sourceOptions: false,
    };
  },
  methods: {
    selectOptions(option) {
      this.selectedSource = option;
      this.sourceOptions = false;
    },
    myCrownCondition(crownNumber = 0, vip = false) {
      const calcCrown = crownNumber < 1 && vip ? 0 : crownNumber;
      return vip ? calcCrown : -1;
    },
    showShopWindow(tab) {
      this.$store.state.modals.nextWindowText = tab;
      this.$store.commit("modals/show", "shop");
      this.$adjust.trackEvent("ClickPlayerDetailShopWindowOn");
      this.closeWindow();
    },
    async caseAction() {
      if (isNaN(parseInt(this.selectedAmount)) || this.selectedAmount == 0) {
        return this.$plugins.toast.show({
          text: this.$t("caseWindow.validAmount"),
          duration: 3000,
          variant: "danger",
        });
      }
      if (this.selectedSource === "game") {
        if (parseInt(this.selectedAmount) <= this.user.availableChip) {
          this.$data.loading = true;
          await this.$store.dispatch("userCase/deposit", this.selectedAmount);
          this.$plugins.toast.show({
            text: this.$t("caseWindow.transaction"),
            duration: 3000,
            variant: "success",
          });
          this.$store.commit("user/setUserData", {
            availableChip:
              this.user.availableChip - parseInt(this.selectedAmount),
            availableChip: this.user.availableChip,
            userCase: {
              total: this.user.userCase.total + parseInt(this.selectedAmount),
            },
          });
          this.$adjust.trackEvent("PlayerChipAccountToCase");
          this.selectedAmount = null;
        } else {
          this.$plugins.toast.show({
            text: this.$t("caseWindow.enoughChip"),
            duration: 3000,
            variant: "danger",
          });
        }
      } else {
        if (parseInt(this.selectedAmount) <= this.user.userCase.total) {
          this.$data.loading = true;
          await this.$store.dispatch("userCase/withdraw", this.selectedAmount);
          this.$plugins.toast.show({
            text: this.$t("caseWindow.transaction"),
            duration: 3000,
            variant: "success",
          });
          this.$store.commit("user/setUserData", {
            availableChip: this.user.availableChip,
            userCase: {
              total: this.user.userCase.total - parseInt(this.selectedAmount),
            },
          });
          this.$adjust.trackEvent("PlayerChipCaseToAccount");
          this.selectedAmount = null;
        } else {
          this.$plugins.toast.show({
            text: this.$t("caseWindow.withdraw"),
            duration: 3000,
            variant: "danger",
          });
        }
      }
      this.$store.dispatch("user/getCurrentUserDetails");
      this.$data.loading = false;
    },
    closeWindow() {
      this.$store.commit("modals/hide", "userCase");
      this.selectedAmount = null;
    },
  },
};
</script>

<style lang="scss" scoped>
.case-container {
  position: relative;
  width: 98%;
  height: 91%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  background: #8f2532;
  border-bottom-left-radius: 25px;
  border-bottom-right-radius: 25px;
  .case {
    width: 100%;
    height: 100%;
    display: flex;
    .case-profile {
      width: 20%;
      height: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      border-right: solid 4px #6f1923;
      text-shadow: rgba(0, 0, 0, 1) 0px 4px 3px;
      .avatar {
        position: relative;
        top: 50px;
      }
      .profil-button {
        position: relative;
        top: 100px;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-evenly;
        .orange-content {
          height: 45px;
          width: 45px;
          border-radius: 23px;
          background: rgb(127, 30, 29);
          background: linear-gradient(0deg, #f49648 0%, #f17e32 100%);
          border: solid 1px #1e0809;
          display: flex;
          justify-content: center;
          align-items: center;
          position: relative;
        }
        .profil-button-chip {
          min-width: 200px;
          font-size: 32px;
          color: #ffffff;
        }
      }
      .case-button {
        position: relative;
        top: 140px;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
    .case-content {
      width: 80%;
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      &-title {
        font-size: 34px;
        margin-top: 20px;
        color: #d5bbbc;
      }
      &-image {
        width: 100%;
        display: flex;
        &-card {
          width: 50%;
          height: 650px;
          display: flex;
          flex-direction: column;
          align-items: center;
          margin-top: 70px;
          &-info {
            font-size: 36px;
            color: #ffffff;
            text-shadow: 0px 3px 3px #000000;
            margin-top: 40px;
          }
          .selector {
            position: relative;
            .source-selector {
              position: relative;
              display: flex;
              align-items: center;
              justify-content: flex-start;
              width: 400px;
              height: 90px;
              margin-top: 20px;
              border-radius: 10px;
              background-color: rgba(black, 0.6);
              border: none;
              font-size: 34px;
              padding-left: 10px;
              color: white;
              cursor: pointer;
              option {
                background: red;
              }
              &-options {
                position: absolute;
                top: 115px;
                height: 180px;
                width: 400px;
                background: #380a0f;
                border-radius: 10px;
                z-index: 9;
                &-game {
                  display: flex;
                  align-items: center;
                  justify-content: flex-start;
                  width: 400px;
                  height: 90px;
                  font-size: 34px;
                  padding-left: 10px;
                  color: #ffffff;
                  border: none;
                  cursor: pointer;
                  transition: all 0.4s ease;
                  &:hover {
                    background: #5d161f;
                  }
                }
                &-game:first-child {
                  border-bottom: 1px solid #fff;
                  border-top-left-radius: 10px;
                  border-top-right-radius: 10px;
                }
                &-game:last-child {
                  border-bottom-left-radius: 10px;
                  border-bottom-right-radius: 10px;
                }
              }
            }
            .language-ok {
              position: absolute;
              right: 30px;
              top: 50px;
              transform: scale(0.9);
              z-index: 2;
            }
          }

          &-amount {
            font-size: 36px;
            color: #d3c1c2;
            text-shadow: 0px 3px 3px #000000;
            margin-top: 20px;
          }
          &-select {
            width: 400px;
            height: 90px;
            background-color: rgba(#000000, 0.4);
            border-radius: 10px;
            margin-top: 80px;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            &-text {
              font-size: 36px;
              color: #ffffff;
              text-shadow: 0px 3px 3px #000000;
              margin-left: 10px;
            }
            svg {
              width: 60px;
              height: 60px;
              fill: #ffffff;
              margin-right: 10px;
            }
          }
          &-select-other {
            width: 400px;
            height: 90px;
            background-color: rgba(#b73f3f, 0.4);
            display: flex;
            border-radius: 10px;
            align-items: center;
            &-text {
              font-size: 36px;
              color: #ffffff;
              text-shadow: 0px 3px 3px #000000;
              margin-left: 10px;
            }
          }
          .window3-image {
            position: relative;
            top: 70px;
          }
        }
        &-case {
          width: 50%;
          height: 650px;
          display: flex;
          flex-direction: column;
          align-items: center;
          margin-top: 70px;
          &-info {
            font-size: 36px;
            color: #ffffff;
            text-shadow: 0px 3px 3px #000000;
            margin-top: 40px;
          }
          &-amount {
            font-size: 36px;
            color: #d3c1c2;
            text-shadow: 0px 3px 3px #000000;
            margin-top: 20px;
          }
          input {
            width: 400px;
            height: 90px;
            margin-top: 30px;
            border-radius: 10px;
            font-size: 34px;
            padding-left: 10px;
            background: #55121b;
            box-shadow: inset 1px 3px 8px black;
            color: #ffffff;
            border: none;
          }
          /* Chrome, Safari, Edge, Opera */
          input::-webkit-outer-spin-button,
          input::-webkit-inner-spin-button {
            -webkit-appearance: none;
            margin: 0;
          }
          /* Firefox */
          input[type="number"] {
            -moz-appearance: textfield;
          }
          .case-amount-input::placeholder {
            color: #ffffff;
          }
          .formatted-amount {
            display: flex;
            justify-content: flex-start;
            align-items: center;
            width: 400px;
            min-height: 65px;
            height: 65px;
            font-size: 32px;
            .format-small {
              position: relative;
              left: 30px;
              color: #ea8042;
            }
          }
          .case-image {
            position: relative;
            top: 70px;
          }
        }
      }
      .case-button {
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        bottom: 50px;
      }
      .desc {
        width: 80%;
        height: 80%;
        font-size: 36px;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
  }
  .no-log {
    position: absolute;
    text-align: center;
    width: 100%;
    font-size: 60px;
    top: 200px;
  }
}
</style>
